<template>
  <Form ref="rulesForm" :model="rulesForm" @submit.native.prevent>
    <Row type="flex" :gutter="gutterItem">
      <!--库区组-->
      <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-if="allowDiffWarehouseBlockGroup">
        <Form-item :label="$t('key1000441')" prop="allowDiffWarehouseBlockGroup">
          <RadioGroup v-model="rulesForm.allowDiffWarehouseBlockGroup">
            <Radio label="0">{{ $t('key1000447') }}</Radio>
            <Radio label="1">{{ $t('key1000448') }}</Radio>
          </RadioGroup>
        </Form-item>
      </Col>
      <!-- 库区 -->
      <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
        <Form-item :label="$t('key1000442')" prop="allowDiffWarehouseBlock">
          <RadioGroup v-model="rulesForm.allowDiffWarehouseBlock">
            <Radio label="0">{{ $t('key1000447') }}</Radio>
            <Radio label="1">{{ $t('key1000448') }}</Radio>
          </RadioGroup>
        </Form-item>
      </Col>
      <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
        <Form-item :label="$t('key1000443')" prop="allowDiffLogisticsDealer">
          <RadioGroup v-model="rulesForm.allowDiffLogisticsDealer">
            <Radio label="0">{{ $t('key1000447') }}</Radio>
            <Radio label="1">{{ $t('key1000448') }}</Radio>
          </RadioGroup>
        </Form-item>
      </Col>
      <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-if="rulesForm.allowDiffLogisticsDealer === '0'">
        <Form-item :label="$t('key1000444')" prop="allowDiffMailMode">
          <RadioGroup v-model="rulesForm.allowDiffMailMode">
            <Radio label="0">{{ $t('key1000447') }}</Radio>
            <Radio label="1">{{ $t('key1000448') }}</Radio>
          </RadioGroup>
        </Form-item>
      </Col>
      <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
        <Form-item :label="$t('key1000445')" prop="regularOrderMax">
          <InputNumber v-model.trim="rulesForm.regularOrderMax" style="width: 100px"
            :formatter="value => `${parseInt(value)}`"
            :precision="0" :min="1" :max="5000">
          </InputNumber>
        </Form-item>
      </Col>
      <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-if="system === 'yms'">
        <Form-item :label="$t('key1000446')" prop="stockListMax">
          <InputNumber v-model.trim="rulesForm.stockListMax" style="width: 100px"
            :formatter="value => `${parseInt(value)}`"
            :precision="0" :min="1" :max="5000">
          </InputNumber>
        </Form-item>
      </Col>
    </Row>
  </Form>
</template>

<script>
import Mixin from "@/components/mixin/common_mixin";

export default {
  name: "pickingRules",
  mixins: [Mixin],
  props: {
    pickingSetInfo: {
      type: Object,
      default: () => {
      }
    },
    allowDiffWarehouseBlockGroup: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      rulesForm: {
        allowDiffWarehouseBlockGroup: '0',
        allowDiffWarehouseBlock: '0',
        allowDiffLogisticsDealer: '0',
        allowDiffMailMode: '0',
        regularOrderMax: this.system === 'yms' ? 20 : 100,
        stockListMax: 20
      }
    }
  },
  watch: {
    // 监听数值的变化
    rulesForm: {
      handler(data) {
        let v = this;
        v.$emit('updateRulesData', data);
      },
      immediate: true,
      deep: true
    },
    pickingSetInfo: {
      handler(val) {
        let obj = {
          allowDiffWarehouseBlockGroup: '0',
          allowDiffWarehouseBlock: '0',
          allowDiffLogisticsDealer: '0',
          allowDiffMailMode: '0',
          regularOrderMax: this.system === 'yms' ? 20 : 100,
          stockListMax: 20
        }
        if (Object.keys(val).length > 0) {
          if (this.system === 'yms') {
            if (val.hasOwnProperty('regularOrderMax') && val.hasOwnProperty('stockListMax')) {
              this.rulesForm = val;
            } else {
              this.rulesForm = JSON.parse(JSON.stringify(obj));
            }
          } else {
            this.rulesForm = val;
          }
        } else {
          this.rulesForm = JSON.parse(JSON.stringify(obj));
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ivu-form-item {
  .ivu-form-item-label {
    font-size: 14px;
  }

  /deep/ .ivu-radio-group {
    position: relative;
    top: -1px;
  }
}
</style>