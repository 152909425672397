<template >
  <div >
    <h5 v-if="createAfterTableData.length !== 0" >
      {{ $t('key1003634') }}{{ createAfterTableData.length }}{{ $t('key1003635') }} </h5 >
    <Table border :columns="columns" :data="createAfterTableData" ></Table >
  </div >
</template >
<script >
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

export default {
  props: ['data', 'createAfterTableData'],
  data () {
    return {
      columns: [
        {
          type: 'index',
          width: 60,
          align: 'center'
        }, {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1003636'),
          key: 'pickingGoodsNo'
        }, {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1002448'),
          key: 'type'
        }, {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1002399'),
          key: 'warehouseName'
        }
      ]
    };
  },
  created () {},
  computed: {},
  destroyed () {
    this.$parent.$parent.createAfterTableData = [];
  }
};
</script >

<style ></style >
